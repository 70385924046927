import { useQuery } from 'src/react-query';
import { canopyPaymentsQueries } from '../canopyPaymentsQueries';

export const useCanopyPaymentsQuery = (override: boolean | undefined = undefined) => {
  const { data = {}, isLoading } = useQuery(canopyPaymentsQueries.paymentsStatus({ override }));
  return {
    amexEnabled: !data?.amex_opt_out && data?.amex_submitted_status === 'APPROVED',
    hasAdyen: data?.has_adyen,
    hasCanopyPayments: data?.is_active || override,
    hasPaysafe: data?.has_paysafe,
    loadingCanopyPayments: isLoading,
    surchargeFee: data?.surcharge_fee,
    surchargeEnabled: data?.surcharge,
    surchargeViewed: data?.surcharge_viewed,
    teamCanKeyInCards: data?.team_can_key_in_cards,
  };
};
