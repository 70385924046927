import React, { useState } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import styles from './time-entry.styles.css';

export default function TimeEntryAssigneeSelector(props) {
  const { disableEdit, loading, onAssigneeChanged, selectedAssignee, teamMembers } = props;
  const [searchVal, setSearchVal] = useState('');

  return (
    <div className={`${styles.editLabelAndInput} cps-form-group`}>
      <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
        <label htmlFor="teamMemberSelect">
          Assignee
          <span className="cps-color-primary">*</span>
        </label>
      </div>
      <div id="teamMemberSelect">
        <CpSelectSingle
          data={teamMembers}
          loading={loading}
          disabled={disableEdit}
          contentWidth="block"
          onChange={onAssigneeChanged}
          searchFilter
          searchOnChange={val => setSearchVal(val)}
          searchValue={searchVal}
          triggerIsBlock
          value={selectedAssignee}
        />
      </div>
    </div>
  );
}
