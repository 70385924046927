import { useQuery } from 'src/react-query';
import { integrationsQueries } from '../integrationsQueries';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';

export const useIntegrationsQuery = () => {
  const [, tenant] = useWithUserAndTenant();

  const { data: integrationsData, refetch } = useQuery(
    integrationsQueries.accountingIntegrations({ enabled: !!tenant?.qbo_credentials_id })
  );

  const integrations = integrationsData?.map((integration: any) => ({
    ...integration,
    checkIntegrationAuth: refetch,
  }));

  return !!tenant?.qbo_credentials_id ? integrations : null;
};
