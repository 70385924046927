import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import { CpLoader } from 'canopy-styleguide!sofe';
import { NavContent } from 'primary-navbar!sofe';
import { useHasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import ClientBillingNavigation from './navigation/client-billing-navigation.component';
import BillingSecondaryNav from './navigation/secondary-nav/secondary-nav.component';
import { useServiceItemsIntegrations } from 'src/common/custom-hooks';
import { useCanopyPaymentsQuery } from './queries/hooks/use-canopy-payments-query';
import { useIntegrationsQuery } from './queries/hooks/use-integrations-query';
import { CanopyPaymentsContext } from 'src/common/canopy-payments-context';
import PaymentsRoutes from './payments/payments-routing.component';
import ReportsRoutes from './reports/reports-routes.component';
import PrivateRoute from './common/private-route.component';
import { IntegrationsContext } from './common/integrations/integrations-context';

const BillingDashboard = lazy(() =>
  import(/* webpackChunkName: "billing-dashboard" */ './dashboard/billing-dashboard.component').then(m => ({
    default: m.BillingDashboard,
  }))
);
const ProductivityDashboard = lazy(() =>
  import(
    /* webpackChunkName; "productivity-dashboard" */ './productivity/dashboard/productivity-dashboard.component'
  ).then(m => ({
    default: m.ProductivityDashboard,
  }))
);
const InvoiceDashboard = lazy(() =>
  import(/* webpackChunkName: "invoice-dashboard" */ './invoices/invoice-dashboard.component')
);
const ExpenseTable = lazy(() =>
  import(/* webpackChunkName: "expense-dashboard" */ './expenses/expense-table.component').then(m => ({
    default: m.ExpenseTable,
  }))
);
const CreditsDashboard = lazy(() =>
  import(/* webpackChunkName: "credits-dashboard" */ './credits/credits-dashboard.component').then(m => ({
    default: m.CreditsDashboard,
  }))
);

const Statements = lazy(() =>
  import(/* webpackChunkName: "statements" */ './statements/statements.component').then(m => ({
    default: m.Statements,
  }))
);
const PaymentSettings = lazy(() =>
  import(/* webpackChunkName: "payment-settings" */ './payment-settings/payment-settings.component').then(m => ({
    default: m.PaymentSettings,
  }))
);

const PaymentReceiptModal = lazy(() =>
  import(
    /* webpackChunkName: "client-payment-receipt" */ './payments/components/receipt/payment-receipt-modal.component'
  ).then(m => ({
    default: m.PaymentReceiptModal,
  }))
);

const TimeDashboard = lazy(() =>
  import(/* webpackChunkName: "time-dashoard" */ './time-entries/time-dashboard/time-dashboard.component').then(m => ({
    default: m.TimeDashboard,
  }))
);

const WIPReportDashboard = lazy(() =>
  import(/* webpackChunkName: "wip-report-dashboard" */ './time-entries/wip/wip-report-dashboard.component').then(
    m => ({
      default: m.WIPReportDashboard,
    })
  )
);

const WIPReportClient = lazy(() =>
  import(/* webpackChunkName: "wip-report" */ './time-entries/wip/wip-report-client.component').then(m => ({
    default: m.WIPReportClient,
  }))
);

const InvoiceEditor = lazy(() =>
  import(/* webpackChunkName: "invoice-dashboard" */ './invoices_v2/invoice-editor.component')
);

export default function Root() {
  const { hasCanopyPayments, amexEnabled, teamCanKeyInCards, hasAdyen } = useCanopyPaymentsQuery();
  const integrations = useIntegrationsQuery();
  const itemsIntegration = useServiceItemsIntegrations();
  const [user, tenant] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';
  const ft_crm = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm');
  const hasBulkInvoicePermission = useHasAccess('bulk_invoicing');
  return (
    <CanopyPaymentsContext.Provider value={{ hasCanopyPayments, amexEnabled, teamCanKeyInCards, hasAdyen }}>
      <IntegrationsContext.Provider value={{ integrations, itemsIntegration }}>
        <HashRouter>
          <Suspense
            fallback={
              <NavContent hasTopnavSecondary={true} clientMenuPossible={true}>
                <CpLoader />
              </NavContent>
            }>
            <BillingSecondaryNav>
              <div>
                {user && !isClient && (
                  <>
                    <Route
                      path="/client/:clientId/billing"
                      render={renderProps => <ClientBillingNavigation {...renderProps} />}
                    />
                    <Route
                      path="/client/:clientId/time"
                      render={renderProps => <ClientBillingNavigation {...renderProps} />}
                    />
                  </>
                )}
                <PrivateRoute path="/billing/dashboard" permissions="billing_dashboard">
                  <BillingDashboard />
                </PrivateRoute>
                <PrivateRoute path="/client/:clientId/billing/dashboard" permissions="billing_dashboard">
                  <BillingDashboard />
                </PrivateRoute>
                {isClient ? (
                  <Route path="/client/:clientId/billing/payments/:paymentId/receipt" component={PaymentReceiptModal} />
                ) : (
                  <PrivateRoute path={['(.+/payments)', '(.+/receipt)']} permissions="billing_payments">
                    <PaymentsRoutes />
                  </PrivateRoute>
                )}
                <PrivateRoute path="(.+/reports)" permissions={['billing_reports', 'time_reports']}>
                  <ReportsRoutes />
                </PrivateRoute>

                <PrivateRoute path="(.*/invoices)" permissions="billing_invoices">
                  <>
                    <Route
                      path="/billing/invoices/:invoiceId?"
                      render={renderProps => (
                        <InvoiceDashboard {...renderProps} integrations={integrations} ft_crm={ft_crm} />
                      )}
                    />
                    <Route
                      path="/client/:clientId/billing/invoices"
                      render={renderProps => (
                        <InvoiceDashboard {...renderProps} integrations={integrations} ft_crm={ft_crm} />
                      )}
                    />
                  </>
                </PrivateRoute>

                {featureEnabled('toggle_gs_invoice_refactor') && (
                  <PrivateRoute exact path="/billing/invoice/:type/editor/:invoiceId?" permissions="billing_invoices">
                    <Route
                      render={({ match }) => {
                        const { type, invoiceId } = match.params;

                        if (
                          type === 'bulk' &&
                          (!featureEnabled('toggle_gs_bulk_invoices') || hasBulkInvoicePermission === false)
                        ) {
                          return <Redirect to="/billing/invoices" />;
                        }

                        return (
                          <InvoiceEditor
                            key={`${type}-${invoiceId}`}
                            hasGroupBilling={
                              tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm')
                            }
                            integrations={integrations}
                            ft_crm={ft_crm}
                            tenant={tenant}
                          />
                        );
                      }}
                    />
                  </PrivateRoute>
                )}

                <Route exact path="/time" render={() => <Redirect to="/time/entries" />} />
                <PrivateRoute path="/time/entries" permissions="time_tracking">
                  <TimeDashboard integrations={integrations} mode="global" />
                </PrivateRoute>
                <Route
                  exact
                  path="/client/:clientId/time"
                  render={({ match }) => <Redirect to={`/client/${match.params.clientId}/time/entries`} />}
                />
                <PrivateRoute path="/client/:clientId/time/entries" permissions="time_tracking">
                  <TimeDashboard integrations={integrations} mode="client" />
                </PrivateRoute>
                <PrivateRoute path="/time/productivity" permissions="time_tracking">
                  <ProductivityDashboard />
                </PrivateRoute>
                <PrivateRoute path="/client/:clientId/time/productivity" permissions="time_tracking">
                  <ProductivityDashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/billing/expenses" permissions="time_tracking">
                  <ExpenseTable integrations={integrations} />
                </PrivateRoute>
                <PrivateRoute exact path="/client/:clientId/billing/expenses" permissions="time_tracking">
                  <ExpenseTable integrations={integrations} />
                </PrivateRoute>

                <PrivateRoute exact path="/billing/credits" permissions="billing_credits">
                  <CreditsDashboard />
                </PrivateRoute>
                <PrivateRoute exact path="/client/:clientId/billing/credits" permissions="billing_credits">
                  <CreditsDashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/billing/statements" permissions="billing_statements">
                  <Statements tenant={tenant} />
                </PrivateRoute>
                <PrivateRoute exact path="/client/:clientId/billing/statements" permissions="billing_statements">
                  <Statements tenant={tenant} />
                </PrivateRoute>

                <PrivateRoute exact path="/time/wip" permissions="time_tracking">
                  <WIPReportDashboard tenant={tenant} />
                </PrivateRoute>
                {ft_crm && (
                  <PrivateRoute exact path="/time/client-groups/wip/:clientGroupId" permissions="time_tracking">
                    <WIPReportDashboard tenant={tenant} />
                  </PrivateRoute>
                )}
                <PrivateRoute exact path="/time/client/wip/:clientId" permissions="time_tracking">
                  <WIPReportClient tenant={tenant} />
                </PrivateRoute>
                {ft_crm && (
                  <PrivateRoute exact path="/time/client-groups/clients/wip/:clientGroupId" permissions="time_tracking">
                    <WIPReportClient tenant={tenant} />
                  </PrivateRoute>
                )}

                {hasAdyen
                  ? hasCanopyPayments &&
                    teamCanKeyInCards && (
                      <PrivateRoute
                        exact
                        path="/client/:clientId/billing/payment-settings"
                        permissions={['client', 'billing_payments']}>
                        <PaymentSettings />
                      </PrivateRoute>
                    )
                  : hasCanopyPayments && (
                      <PrivateRoute
                        exact
                        path="/client/:clientId/billing/payment-settings"
                        permissions={['client', 'billing_payments']}>
                        <PaymentSettings />
                      </PrivateRoute>
                    )}
              </div>
            </BillingSecondaryNav>
          </Suspense>
        </HashRouter>
      </IntegrationsContext.Provider>
    </CanopyPaymentsContext.Provider>
  );
}
