import React, { useState, useEffect } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { clientQueries } from 'clients-ui/externals!sofe';
import { useQuery } from 'src/react-query';
import { handleError } from 'src/common/handle-error.helper';
import { getClients } from 'src/resources/clients.resources.js';

export const SelectClient = ({ clientId, readOnly, onClientChanged }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data: clientQueryData } = useQuery(clientQueries.client({ clientId }));

  useEffect(() => {
    if (clientQueryData?.id) {
      setSelectedClient({ id: clientQueryData.id, name: clientQueryData.name });
    } else {
      setSelectedClient(null);
    }
  }, [clientQueryData?.id]);

  useEffect(() => {
    if (searchValue.length > 1) {
      setLoading(true);
      const clientsSubscription = getClients(searchValue).subscribe(clients => {
        setClients(clients);
        setLoading(false);
      }, handleError);

      return () => clientsSubscription.unsubscribe();
    }
  }, [searchValue]);

  return (
    <div className="cp-mb-24" style={{ flex: 1 }}>
      <div className="cp-mb-4">
        <label htmlFor="clientSelect">
          Client name <span className="cps-input-required" />
        </label>
      </div>
      {readOnly ? (
        <input className="cps-form-control" disabled value={selectedClient?.name || ''} />
      ) : (
        <CpSelectSingle
          clearable
          contentWidth="block"
          data={clients}
          placeholder="Enter a client name"
          loading={loading}
          onChange={client => {
            setSelectedClient(client);
            onClientChanged(client);
          }}
          searchOnChange={setSearchValue}
          searchValue={searchValue}
          triggerIsBlock
          value={selectedClient}
        />
      )}
    </div>
  );
};
