import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { filter, omit, remove, unionBy, isEmpty } from 'lodash';
import moment from 'moment';
import { DateTime } from 'luxon';
import toasts from 'toast-service!sofe';
import { showInviteClientModal } from 'clients-ui!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { handleError } from 'src/common/handle-error.helper';
import {
  isValidPaymentDetails,
  maybeSaveCard,
  maybeSaveAccount,
  separateName,
  paymentViewMode,
} from 'src/payments/payments.helper';
import {
  getColumns,
  getDefaultInvoice,
  getDefaultRecurrence,
  getNewLineItem,
  mapInvoiceDtoToFormData,
  mapInvoiceFormDataToDto,
  mapRecurrenceDtoToFormData,
  mapRecurrenceFormDataToDto,
  calculateSubTotal,
} from './components/invoice-builder/invoice-builder.helper';
import {
  invoiceCalc,
  invoiceTypes,
  modes,
  prepareAndPreviewInvoice,
  prepareAndSaveInvoice,
  sendInvoice,
  sendNotification,
} from 'src/invoices/invoices.helper';
import {
  calculateInitialInvoiceDate,
  calculateFinalInvoiceDate,
  prepareRecurrence,
} from 'src/invoices/recurrences.helper';
import { getClientGroups, getUsersAndAdmins } from 'src/resources/clients.resources';
import { getAvailableCredits } from 'src/resources/credits.resources';
import {
  getNextInvoiceNumber,
  archiveInvoice,
  deleteInvoice,
  updateInvoice,
  restoreInvoice,
  unarchiveInvoice,
  createRecurrence,
  updateRecurrence,
  deleteRecurrence,
} from 'src/resources/invoices.resources';
import { updateCanopyPayment } from 'src/resources/payments.resources';
import { updateCard, updateBankAccount } from 'src/resources/payment-settings.resources';
import { getWIPTimeEntries } from 'src/resources/wip-report.resources';
import { useAdyenPayments } from 'src/payments/adyen-payments.hook.js';
import { toNumber } from './invoices.helper';

export const useInvoiceBuilder = (
  show,
  onClose,
  onViewInvoiceFromRecurrence,
  onReturnToRecurrence,
  serviceItems,
  defaultSettings,
  hasCanopyPayments,
  hasAdyen,
  teamCanKeyInCards,
  tenant
) => {
  //unique identifier for updating invoice number sequencing
  const sourceId = useMemo(() => Math.random().toString(36).substring(2), []);
  const [invoiceType, setInvoiceType] = useState(invoiceTypes.oneTime);
  const [mode, setMode] = useState();
  const [invoice, setInvoice] = useState(getDefaultInvoice());
  const [recurrence, setRecurrence] = useState(getDefaultRecurrence());
  const [availableCredits, setAvailableCredits] = useState([]);
  const [groupLineItems, setGroupLineItems] = useState(defaultSettings?.line_items?.id === 'grouped');
  const [associatedTimeEntries, setAssociatedTimeEntries] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paysafeValid, setPaysafeValid] = useState(false);
  const [invoicePreview, setInvoicePreview] = useState();
  const [webViewerInstance, setWebViewerInstance] = useState();
  const [clientGroupClients, setClientGroupClients] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [options, setOptions] = useState({
    useCredit: true,
    singleLine: false,
    termsConditions: false,
    clientNote: false,
    recurringPayments: false,
  });
  const [needToSave, setNeedToSave] = useState(false);
  const [errors, setErrors] = useState();
  const [disableActions, setDisableActions] = useState(false);
  const { adyenPaymentDetails, adyenActions } = useAdyenPayments({ paymentViewMode: paymentViewMode.Payments });
  const history = useHistory();

  const ft_crm = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm');

  useEffect(() => {
    let pusherSubscription;
    SystemJS.import('fetcher!sofe').then(({ onPusher }) => {
      pusherSubscription = onPusher('invoices').subscribe(response => {
        if (response.source_id === sourceId) return;
        handleUpdateInvoiceFormData('invoiceNumber', response.invoice_number);
        toasts.infoToast('Invoice number was updated to the next usable number');
      }, handleError);
    });
    return () => {
      pusherSubscription && pusherSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!show) {
      setInvoiceType(invoiceTypes.oneTime);
      setMode(null);
      setOptions({
        useCredit: true,
        singleLine: false,
        termsConditions: false,
        clientNote: false,
        recurringPayments: false,
      });
      setGroupLineItems(defaultSettings?.line_items?.id === 'grouped');
      setInvoice(getDefaultInvoice());
      setRecurrence(getDefaultRecurrence());
      setAssociatedTimeEntries([]);
      setNeedToSave(false);
      setErrors();
      adyenPaymentDetails?.adyenSessionDetails && adyenActions?.setAdyenSessionDetails();
    }
  }, [show, defaultSettings]);

  useEffect(() => {
    if (!show || mode != modes.create || invoice.invoiceNumber) return;
    const nextInvoiceSubscription = getNextInvoiceNumber().subscribe(invoiceNumber => {
      handleUpdateInvoiceFormData('invoiceNumber', invoiceNumber, false);
      featureEnabled('toggle_gs_concurrent_invoice') &&
        handleUpdateInvoiceFormData('initialInvoiceNumber', invoiceNumber, false);
    }, handleError);

    return () => nextInvoiceSubscription.unsubscribe();
  }, [show, mode]);

  useEffect(() => {
    if (!show || mode !== modes.create || invoice.id || recurrence.id) return;
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      columnVisibility: getColumns(false, defaultSettings?.display_fields.includes('client'))
        .filter(c => defaultSettings?.display_fields.includes(c.key))
        .map(c => ({ id: c.key, name: c.label })),
      dueDate: moment(prevInvoice.invoiceDate).add(defaultSettings.terms, 'days'),
      terms: defaultSettings.payment_terms || { id: 0, name: 'Due on Receipt' },
      termsConditions: defaultSettings.terms_and_conditions || '',
      includeSpouseName: defaultSettings?.include_spouse_name || false,
    }));
    setRecurrence(prevRecurrence => ({
      ...prevRecurrence,
      recurringTerms: defaultSettings.payment_terms || { id: 0, name: 'Due on Receipt' },
      fixedTerm: defaultSettings.terms || 0,
      includeSpouseName: defaultSettings?.include_spouse_name || false,
    }));
    setOptions(prevOptions => ({
      ...prevOptions,
      termsConditions: defaultSettings.show_terms_and_conditions,
    }));
    setGroupLineItems(defaultSettings?.line_items?.id === 'grouped');
  }, [defaultSettings]);

  useEffect(() => {
    if (!invoice.client?.id) return;

    getUsersAndAdmins(invoice.client.id).subscribe(clients => {
      setClientUsers(filter(clients, obj => obj.role === 'Client'));
    }, handleError);

    if (featureEnabled('toggle_credit_on_invoices') && !featureEnabled('toggle_gs_invoice_refactor')) {
      getAvailableCredits(invoice.client.id).subscribe(({ credits }) => {
        setAvailableCredits(
          credits
            .filter(credit => credit.available !== '0.00')
            .map(credit => ({ ...credit, amountToApply: 0 }))
            .sort((a, b) => DateTime.fromISO(a.date) - DateTime.fromISO(b.date))
        );
      });
    }
  }, [show, invoice.client]);

  useEffect(() => {
    if (!show || !invoice?.clientGroup?.id || (invoice.clientGroup?.id && invoice.clientGroup?.is_active === false))
      return;
    const clientSubscription = getClientGroups(invoice.clientGroup.id).subscribe(response => {
      setInvoice(prevInvoice => ({
        ...prevInvoice,
        clientGroup: { ...prevInvoice.clientGroup, is_active: true },
      }));
      setClientGroupClients(response.clients);
    }, handleError);

    return () => clientSubscription.unsubscribe();
  }, [invoice?.clientGroup?.id]);

  useEffect(() => {
    //we want this to run if we are modifying an existing invoice or a draft (drafts are in create mode)
    if (!invoice.client || (mode !== modes.edit && !(mode === modes.create && invoice.draft))) return;
    let currentTimeEntries = [];
    let currentExpenses = [];
    invoice.lineItems?.fromTime?.forEach(item => {
      item?.subLineItems?.forEach(subLineItem => {
        if (subLineItem.timeEntryId) {
          currentTimeEntries.push(subLineItem.timeEntryId);
        } else {
          subLineItem?.relationships?.sources?.forEach(({ type, id }) => {
            type === 'expense' ? currentExpenses.push(id) : currentTimeEntries.push(id);
          });
        }
      });
      item?.relationships?.sources?.forEach(({ type, id }) => {
        type === 'expense' ? currentExpenses.push(id) : currentTimeEntries.push(id);
      });
    });

    if (currentTimeEntries.length || currentExpenses.length) {
      const subscription = getWIPTimeEntries(
        {
          ...(!invoice.clientGroup?.id && { client_id: invoice.client.id }),
          ...(invoice.clientGroup?.id &&
            invoice.clientGroup?.is_active && {
              client_group_id: invoice.clientGroup?.id,
            }),
          time_entry_ids: currentTimeEntries,
          expense_ids: currentExpenses,
          include_billed: true,
        },
        1,
        currentExpenses.length + currentTimeEntries.length
      ).subscribe(timeEntries => {
        setAssociatedTimeEntries(timeEntries.rows);
      }, handleError);

      return () => subscription.unsubscribe();
    }
  }, [mode]);

  useEffect(() => {
    if (!show || !invoice.invoiceDate || invoice.terms.id === -1 || !(mode === modes.create || mode === modes.edit))
      return;
    const dueDate = moment(invoice.invoiceDate).add(invoice.terms.id || 0, 'days');
    handleUpdateInvoiceFormData('dueDate', dueDate, false);
  }, [invoice.invoiceDate, invoice.terms]);

  useEffect(() => {
    if (!show || !invoice || !(mode === modes.create || mode === modes.edit)) return;
    if (
      invoiceType === invoiceTypes.recurring &&
      options.recurringPayments &&
      invoiceCalc.getTotal(invoice.invoice_line_items) <= 0
    ) {
      handleToggleOption('recurringPayments');
    }

    handleUpdateCreditsUsed(availableCredits, true);
  }, [invoice.lineItems.fromTime, invoice.lineItems.standard]);

  const validateLineItem = lineItem => {
    return (
      lineItem.service ||
      lineItem.description ||
      lineItem.amount ||
      lineItem.discount ||
      lineItem.wuwd ||
      lineItem.hidden
    );
  };

  const hasLineItem = () => {
    return (
      invoice.lineItems.standard.filter(validateLineItem).length > 0 ||
      invoice.lineItems.fromTime.filter(validateLineItem).length > 0
    );
  };

  const hasValidPayment = () => {
    if (!options.recurringPayments) {
      return true;
    } else {
      if (!paymentDetails.paymentMethod) {
        return false;
      }
      const { method, ccInfo, achInfo } = paymentDetails.paymentMethod;
      return isValidPaymentDetails(method, DateTime.local(), ccInfo, achInfo, paymentDetails.paysafeInstance);
    }
  };

  useEffect(() => {
    if (!show) return;

    const isRecurringInvoice = invoiceType === invoiceTypes.recurring;

    if (
      isRecurringInvoice &&
      hasAdyen &&
      teamCanKeyInCards &&
      options.recurringPayments &&
      !adyenPaymentDetails?.adyenSessionDetails
    ) {
      adyenActions.handleAdyenSession({
        clientId: invoice?.client?.id,
      });
    }
  }, [show, options, invoiceType]);

  useEffect(() => {
    if (!show) return;

    let validationErrors = [];
    const isRecurringInvoice = invoiceType === invoiceTypes.recurring;

    if (!invoice.client?.id) {
      validationErrors = [...validationErrors, 'noClient'];
    }
    if (isRecurringInvoice) {
      if (!recurrence.description?.trim()) {
        validationErrors = [...validationErrors, 'noDescription'];
      }
      if (!recurrence.startDate) {
        validationErrors = [...validationErrors, 'noStartDate'];
      } else if (!recurrence.started && moment().isAfter(recurrence.startDate, 'day')) {
        validationErrors = [...validationErrors, 'invalidStartDate'];
      }
      if (recurrence.endOption === 'On date') {
        if (!recurrence.endDate) {
          validationErrors = [...validationErrors, 'noEndDate'];
        } else {
          const recurrenceDto = mapRecurrenceFormDataToDto(recurrence);
          const initialInvoiceDate = calculateInitialInvoiceDate(recurrenceDto);
          const finalInvoiceDate = calculateFinalInvoiceDate(recurrenceDto, initialInvoiceDate);
          if (finalInvoiceDate.isBefore(initialInvoiceDate)) {
            validationErrors = [...validationErrors, 'invalidEndDate'];
          }
        }
      }
    } else {
      if (!invoice.invoiceNumber?.trim()) {
        validationErrors = [...validationErrors, 'noInvoiceNumber'];
      }
      if (!invoice.invoiceDate) {
        validationErrors = [...validationErrors, 'noInvoiceDate'];
      }
      if (!invoice.dueDate) {
        validationErrors = [...validationErrors, 'noDueDate'];
      }
      if (moment(invoice.invoiceDate).isAfter(moment(invoice.dueDate), 'day')) {
        validationErrors = [...validationErrors, 'invalidDueDate'];
      }
      if (moment(invoice.invoiceDate).isAfter(moment(), 'day')) {
        validationErrors = [...validationErrors, 'invalidInvoiceDate'];
      }
    }
    if (invoiceCalc.getBalanceDue(invoice) < 0) {
      validationErrors = [...validationErrors, 'overpaid'];
    }
    if (!hasLineItem()) {
      validationErrors = [...validationErrors, 'noLineItems'];
    }

    if (!hasAdyen) {
      if (!hasValidPayment()) {
        validationErrors = [...validationErrors, 'invalidPayment'];
      }
    }

    if (hasAdyen && options.recurringPayments) {
      //Adyen: Checks if new credit card or ach form and the form is not filled out
      if (!adyenPaymentDetails?.adyenInstance && isEmpty(adyenPaymentDetails?.paymentMethod)) {
        validationErrors = [...validationErrors, 'invalidPayment'];
      }
      //Adyen: Checks if all Adyen fields are filled and enables/disables based on saved method option
      if (
        adyenPaymentDetails?.adyenInstance &&
        adyenPaymentDetails?.savedMethod &&
        !adyenPaymentDetails?.paymentInfo?.nickname?.trim()
      ) {
        validationErrors = [...validationErrors, 'invalidPayment'];
      }
      //Adyen: Checks if chosen saved credit card method is expired and disables until card is updated
      if (
        adyenPaymentDetails?.paymentMethod?.card_type !== 'ach' &&
        adyenPaymentDetails?.paymentMethod?.isExpired &&
        !adyenPaymentDetails?.adyenInstance
      ) {
        validationErrors = [...validationErrors, 'invalidPayment'];
      }
    }

    if (options.singleLine && !invoice.singleLineDescription?.trim()) {
      validationErrors = [...validationErrors, 'noDescription'];
    }

    setErrors(prevErrors => ({ ...prevErrors, validationErrors }));
  }, [
    show,
    invoiceType,
    invoice,
    recurrence,
    paymentDetails,
    paysafeValid,
    options,
    adyenPaymentDetails.adyenInstance,
    adyenPaymentDetails.paymentMethod,
    adyenPaymentDetails.savedMethod,
    adyenPaymentDetails.paymentInfo.nickname,
  ]);

  const handleUpdateInvoiceFormData = (field, value, isDirty = true) => {
    setInvoice(prevInvoice => ({ ...prevInvoice, [field]: value }));
    if (isDirty) {
      setNeedToSave(true);
    }
  };

  const handleUpdateInvoiceFormDataWithPrev = updater => {
    setInvoice(prevInvoice => updater(prevInvoice));
    setNeedToSave(true);
  };

  const handleUpdateRecurrenceFormData = (field, value) => {
    setRecurrence(prevRecurrence => ({ ...prevRecurrence, [field]: value }));
    setNeedToSave(true);
  };

  const handleUpdateRecurringPayment = (paysafeInstance, paymentDate, paymentMethod) => {
    setPaymentDetails({ paysafeInstance, paymentDate, paymentMethod });
  };

  const handlePaysafeChanged = valid => {
    setPaysafeValid(valid);
  };

  const handleToggleOption = option => {
    setOptions(prevOptions => ({ ...prevOptions, [option]: !options[option] }));
    setNeedToSave(true);
  };

  const handleLoadInvoice = invoiceToLoad => {
    const invoiceForm = mapInvoiceDtoToFormData(invoiceToLoad);

    const optionsToLoad = { ...options };
    if (invoiceToLoad.single_line_item) {
      optionsToLoad.singleLine = true;
    }
    if (invoiceToLoad.display_fields.includes('terms_conditions') && invoiceToLoad.terms_conditions.trim()) {
      optionsToLoad.termsConditions = true;
    }
    if (invoiceToLoad.client_notes.trim()) {
      optionsToLoad.clientNote = true;
    }

    setGroupLineItems(invoiceForm.isGrouped);
    setOptions(optionsToLoad);
    setInvoice(invoiceForm);

    if (invoiceToLoad.draft) {
      setMode(modes.create);
    }
  };

  const handleLoadRecurrence = (invoiceToLoad, recurrenceToLoad) => {
    handleLoadInvoice(invoiceToLoad);
    const recurrenceForm = mapRecurrenceDtoToFormData(recurrenceToLoad);
    setRecurrence(recurrenceForm);
    setInvoice(invoiceForm => ({
      ...invoiceForm,
      includeSpouseName: recurrenceForm.includeSpouseName,
      isBusinessClient: recurrenceForm.isBusinessClient,
    }));
    if (recurrenceForm.payment.cardId) {
      setOptions(prevOptions => ({ ...prevOptions, recurringPayments: true }));
    }
  };

  const handleAddLineItem = () => {
    const newLineItems = [...invoice.lineItems.standard, getNewLineItem()];
    handleUpdateInvoiceFormData('lineItems', {
      standard: newLineItems,
      fromTime: invoice.lineItems.fromTime,
      hidden: invoice.lineItems.hidden,
      lateFees: invoice.lineItems.lateFees,
    });
  };

  const handleAddTimeEntry = (timeEntries, clearExisting = false, overrideGroupSetting) => {
    let groupLines = groupLineItems;
    if (overrideGroupSetting !== undefined) {
      groupLines = overrideGroupSetting;
    }

    setAssociatedTimeEntries(prevState => unionBy(prevState, timeEntries, 'id'));
    const newLineItems = clearExisting ? [] : [...invoice.lineItems.fromTime];

    timeEntries.forEach(timeEntry => {
      let description =
        new DOMParser()
          .parseFromString(timeEntry.description?.replace(/(<\/li>|<\/p>)/g, ' $1') || '', 'text/html')
          .body.textContent.trim() || '';

      const assigneeAmount = parseFloat(
        timeEntry.wip_type === 'expense' ? timeEntry.unbilled_revenue : timeEntry.assignee_amount
      );
      const assigneeRate =
        timeEntry.wip_type === 'expense'
          ? timeEntry.unbilled_revenue
          : timeEntry.assignee
          ? parseFloat(timeEntry.assignee.rate)
          : parseFloat(timeEntry.assignee_rate);
      const client = timeEntry.client;
      //TODO: Check to see if we can run handleFilterEntryItems with the associated client group
      const clientGroup = invoice.clientGroup;
      const serviceRate = timeEntry.service_item ? parseFloat(timeEntry.service_item.rate) : 0;
      const serviceRateType = timeEntry.service_item ? timeEntry.service_item.rate_type : 'hour';
      const taxRate = timeEntry.service_item ? parseFloat(timeEntry.service_item.tax_rate_percent) : 0;
      const assignee = timeEntry.assignee?.name;
      const timeEntryDate = timeEntry.date;
      const task = timeEntry.task?.name;
      const subtask = timeEntry.subtask?.name;
      const wipType = timeEntry.wip_type;
      const importedServiceItem = serviceItems?.find(s => s.id.toString() === timeEntry.service_item?.id) || [];

      if (groupLines && (timeEntry.service_item?.id || wipType === 'expense')) {
        if (
          !newLineItems.find(lineItem =>
            wipType === 'expense' ? lineItem.name === 'Expense' : lineItem.service?.id === timeEntry.service_item?.id
          )
        ) {
          newLineItems.push({
            id: Math.ceil(Math.random() * 100000),
            service: timeEntry.service_item,
            serviceRateType,
            serviceRate,
            assigneeRate,
            description: importedServiceItem?.description || '',
            name: wipType === 'expense' ? 'Expense' : '',
            quantity: wipType === 'expense' ? 1 : 0,
            rateType: wipType === 'expense' ? 'other' : 'employee',
            rate: assigneeRate,
            amount: wipType === 'expense' ? 1 : 0,
            wuwd: 0,
            discount: 0,
            discountIsPercent: true,
            taxRate,
            total: 0,
            subLineItems: [],
          });
        }

        const parent = newLineItems.find(lineItem =>
          wipType === 'expense' ? lineItem.name === 'Expense' : lineItem.service?.id === timeEntry.service_item?.id
        );

        const subItem = invoice?.lineItems?.fromTime?.find(lineItem => {
          const sources = lineItem?.relationships?.sources || [];
          return sources[0]?.id === timeEntry?.id;
        });

        const lineItem = invoice?.lineItems?.fromTime?.find(lineItem => {
          return lineItem?.service?.id === timeEntry?.service_item?.id;
        });

        const wuwd =
          invoice?.lineItems?.fromTime
            .filter(item =>
              wipType === 'expense'
                ? item?.name === 'Expense'
                : item?.service && item?.service?.id === lineItem?.service?.id
            )
            .reduce((sum, item) => sum + parseFloat(item.wuwd || 0), 0) || 0;

        parent?.subLineItems?.push({
          id: subItem?.id || Math.ceil(Math.random() * 100000),
          expenseId: wipType === 'expense' ? timeEntry.id : null,
          timeEntryId: wipType === 'expense' ? null : timeEntry.id,
          service: timeEntry.service_item,
          serviceRate,
          assigneeRate,
          clientGroup,
          description: description,
          name: wipType === 'expense' ? 'Expense' : '',
          quantity: wipType === 'expense' ? 1 : timeEntry.duration,
          rateType: wipType === 'expense' ? 'other' : 'employee',
          rate: assigneeRate,
          taxRate,
          amount: assigneeAmount,
          total: assigneeAmount,
          wuwd: subItem?.wuwd || 0,
          relationships: {
            client,
            sources: [{ type: wipType, id: timeEntry.id }],
          },
          assignee,
          timeEntryDate,
          task,
          subtask,
        });
        parent.quantity = parent.subLineItems?.reduce((sum, item) => sum + parseFloat(item.quantity), 0);
        parent.amount = parent.subLineItems?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
        parent.assigneeRate = parent.amount / parent.quantity;
        parent.rate = parent.amount / parent.quantity;
        parent.total = parent.amount + wuwd;
        parent.wuwd = wuwd;
      } else {
        const lineItem = invoice.lineItems.fromTime.find(
          lineItem => lineItem.service?.id === timeEntry.service_item?.id || lineItem?.name === 'Expense'
        );

        const subItem = lineItem?.subLineItems?.find(
          lineItem => lineItem.relationships?.sources[0].id === timeEntry.id
        );

        const noServiceLineItem = invoice.lineItems.fromTime.find(lineItem => {
          return !lineItem.service?.id && lineItem.relationships?.sources?.[0].id === timeEntry.id;
        });
        const groupWuwd =
          invoice?.lineItems?.fromTime
            .filter(item =>
              wipType === 'expense'
                ? item.name === 'Expense'
                : item?.service && item?.service?.id === lineItem?.service?.id
            )
            .reduce((sum, item) => sum + parseFloat(item.wuwd || 0), 0) || 0;
        const wuwd = noServiceLineItem ? noServiceLineItem.wuwd : subItem?.wuwd || 0;

        newLineItems.push({
          id: subItem?.id || Math.ceil(Math.random() * 100000),
          expenseId: wipType === 'expense' ? timeEntry.id : null,
          name: wipType === 'expense' ? 'Expense' : '',
          service: timeEntry.service_item,
          serviceRateType,
          serviceRate,
          assigneeRate,
          clientGroup,
          description: description || importedServiceItem?.description || '',
          quantity: wipType === 'expense' ? 1 : timeEntry.duration,
          rateType: wipType === 'expense' ? 'other' : 'employee',
          rate: assigneeRate,
          amount: assigneeAmount,
          wuwd,
          groupWuwd,
          discount: 0,
          discountIsPercent: true,
          taxRate,
          total: assigneeAmount + +wuwd,
          assignee,
          task,
          subtask,
          timeEntryDate,
          relationships: {
            client,
            sources: [{ type: wipType, id: timeEntry.id }],
          },
        });
      }
    });

    newLineItems.sort((a, b) =>
      a.service?.id && b.service?.id ? a.service.name.localeCompare(b.service.name) : a.service?.id ? -1 : 1
    );

    const hasEmptyLineItem =
      invoice.lineItems.standard.length === 1 &&
      invoice.lineItems.standard.filter(lineItem => lineItem.service || lineItem.description || lineItem.rate)
        .length === 0 &&
      newLineItems.length;

    handleUpdateInvoiceFormData('lineItems', {
      standard: hasEmptyLineItem ? [] : invoice.lineItems.standard,
      fromTime: newLineItems,
      hidden: invoice.lineItems.hidden,
      lateFees: invoice.lineItems.lateFees,
    });
  };

  const handleUpdateLineItem = (index, changedValues, fromTime) => {
    const updater = prevInvoice => {
      const updatedLineItems = fromTime ? [...prevInvoice.lineItems.fromTime] : [...prevInvoice.lineItems.standard];

      let prevValues = { ...updatedLineItems[index] };
      let newValues = { ...changedValues };

      const updatedTimeRate =
        changedValues.rate && toNumber(parseFloat(changedValues.rate), 2) !== toNumber(prevValues.rate, 2);

      if (!fromTime && changedValues.service?.id) {
        newValues.rateType = 'service';
      } else if (!fromTime && changedValues.service && !changedValues.service.id) {
        newValues.rateType = 'other';
      } else if (updatedTimeRate) {
        newValues.rateType = 'other';
      } else if (changedValues.rateType) {
        if (changedValues.rateType === 'service') {
          newValues.rate = prevValues.serviceRate;
          if (prevValues.serviceRateType === 'item') {
            newValues.quantity =
              newValues?.subLineItems?.reduce((sum, item) => sum + parseFloat(item.quantity), 0) || 1;
            newValues?.subLineItems?.forEach(item => {
              item.wuwd = 0;
              item.rate = item.rate || item.service?.rate;
              item.amount = +item.quantity * +item.rate;
            });
          }
          if (prevValues.serviceRateType === 'hour') {
            newValues.subLineItems = newValues.subLineItems?.map(item => {
              return {
                ...item,
                rate: item.rate || item.service?.rate,
                amount: +item.quantity * +(item.rate || item.service?.rate),
              };
            });
          }
        } else if (changedValues.rateType === 'employee' || changedValues.rateType === 'other' || updatedTimeRate) {
          newValues.rate = prevValues.assigneeRate;
          if (prevValues.subLineItems?.length) {
            //for grouped line items
            const quantity = prevValues.subLineItems.reduce(
              (acc, subLineItem) => acc + parseFloat(subLineItem.quantity),
              0
            );
            const amount = prevValues.subLineItems.reduce(
              (acc, subLineItem) => acc + parseFloat(subLineItem.amount),
              0
            );
            newValues.quantity = quantity;
            if (!prevValues.assigneeRate) {
              newValues.assigneeRate = amount / quantity;
              newValues.rate = amount / quantity;
            }
            newValues.subLineItems = newValues.subLineItems?.map(item => {
              return {
                ...item,
                amount: +item.quantity * +item.rate,
              };
            });
          } else {
            //for separated line items
            newValues.quantity = fromTime
              ? prevValues.relationships?.sources[0]?.type === 'expense'
                ? 1
                : associatedTimeEntries.find(
                    entry =>
                      entry.id === prevValues.relationships?.sources?.find(source => source.type === 'time_entry')?.id
                  )?.duration
              : prevValues.quantity;
          }
        }
      }

      if (newValues.subLineItems?.length > 0) {
        const quantity = newValues.subLineItems.reduce((sum, item) => sum + parseFloat(item.quantity), 0);
        const amount = newValues.subLineItems.reduce((sum, item) => sum + parseFloat(item.amount), 0);
        newValues.rate = amount / quantity;
      }

      const quantity = parseFloat(newValues.quantity !== undefined ? newValues.quantity : prevValues.quantity || 0);
      const rate = parseFloat(
        newValues.rate !== undefined
          ? newValues.rate
          : changedValues.rate !== undefined
          ? changedValues.rate
          : prevValues.rate || 0
      );

      const amount = rate * quantity;

      let wuwd =
        changedValues.rateType === 'service' && prevValues.serviceRateType === 'item'
          ? 0
          : parseFloat((changedValues.wuwd !== undefined ? changedValues.wuwd : prevValues.wuwd) || 0);

      const discountIsPercent =
        changedValues.discountIsPercent !== undefined
          ? changedValues.discountIsPercent
          : prevValues.discountIsPercent || false;
      let discountValue = parseFloat(
        (changedValues.discount !== undefined ? changedValues.discount : prevValues.discount) || 0
      );
      const discount = discountIsPercent ? (100 - discountValue) / 100 : discountValue;

      let total = amount + wuwd;
      if (discountIsPercent) {
        total *= discount;
      } else {
        total -= discount;
      }

      const relationships = { ...prevValues.relationships, ...newValues?.relationships };

      newValues.rate = rate;
      newValues.amount = amount;
      newValues.wuwd = wuwd;
      newValues.discount = discountValue;
      newValues.total = total;
      newValues.relationships = relationships;

      updatedLineItems[index] = { ...updatedLineItems[index], ...newValues };

      return {
        ...prevInvoice,
        lineItems: {
          standard: fromTime ? prevInvoice.lineItems.standard : updatedLineItems,
          fromTime: fromTime ? updatedLineItems : prevInvoice.lineItems.fromTime,
          hidden: prevInvoice.lineItems.hidden,
          lateFees: prevInvoice.lineItems.lateFees,
        },
      };
    };

    handleUpdateInvoiceFormDataWithPrev(updater);
  };

  const handleFilterEntryItems = (id, type) => {
    const filteredTimeEntries = associatedTimeEntries.filter(entry => entry.client.id == id);
    setAssociatedTimeEntries(prevState => prevState.filter(entry => entry.client.id == id));
    handleAddTimeEntry(filteredTimeEntries, true, groupLineItems);
    invoice.lineItems.standard.forEach((lineItem, index) => {
      const clientOrGroupId =
        type === 'group' ? lineItem.relationships?.client?.client_group_id : lineItem.relationships?.client?.id;
      if (clientOrGroupId !== id) {
        handleUpdateLineItem(index, { relationships: { client: null } });
      }
    });
    if (!filteredTimeEntries.length && ![...invoice.lineItems.standard, ...invoice.lineItems.lateFees].length) {
      setInvoice(prevInvoice => ({
        ...prevInvoice,
        lineItems: {
          ...prevInvoice.lineItems,
          standard: [...prevInvoice.lineItems.standard, getNewLineItem()],
        },
      }));
    }
  };

  const handleRemoveLineItem = (lineItemToRemove, fromTime, isLateFee) => {
    const updatedLineItems = [
      ...(fromTime ? invoice.lineItems.fromTime : []),
      ...(isLateFee ? invoice.lineItems.lateFees : []),
      ...(!fromTime && !isLateFee ? invoice.lineItems.standard : []),
    ];
    let updatedTimeEntries = [...associatedTimeEntries];

    remove(updatedLineItems, lineItem => lineItem.id === lineItemToRemove.id);

    handleUpdateInvoiceFormData('lineItems', {
      standard: !fromTime && !isLateFee ? updatedLineItems : invoice.lineItems.standard,
      fromTime: fromTime ? updatedLineItems : invoice.lineItems.fromTime,
      hidden: invoice.lineItems.hidden,
      lateFees: isLateFee ? updatedLineItems : invoice.lineItems.lateFees,
    });

    if (fromTime) {
      if (lineItemToRemove.subLineItems?.length > 0) {
        lineItemToRemove.subLineItems.forEach(subLineItem => {
          const timeEntryId = subLineItem.relationships.sources[0].id;
          setAssociatedTimeEntries(updatedTimeEntries.filter(timeEntry => timeEntry.id !== timeEntryId));
        });
      } else if (!groupLineItems && lineItemToRemove.relationships?.sources?.length > 0) {
        const timeEntryId = lineItemToRemove.relationships.sources[0].id;
        setAssociatedTimeEntries(updatedTimeEntries.filter(timeEntry => timeEntry.id !== timeEntryId));
      }
    }
  };

  const handleRemoveTimeEntry = (lineItemId, timeEntryToRemove) => {
    const timeEntryId = timeEntryToRemove.relationships.sources[0].id;
    const updatedTimeEntries = associatedTimeEntries.filter(timeEntry => timeEntry.id !== timeEntryId);

    setAssociatedTimeEntries(updatedTimeEntries);

    const updatedLineItems = [...invoice.lineItems.fromTime];
    const index = updatedLineItems.findIndex(li => li.id === lineItemId);
    const lineItem = updatedLineItems[index];

    remove(lineItem.subLineItems, subLineItem => subLineItem.id === timeEntryToRemove.id);

    if (lineItem.subLineItems.length === 0) {
      handleRemoveLineItem(lineItem, true);
      return;
    }

    const quantity = lineItem.subLineItems.reduce((sum, item) => sum + parseFloat(item.quantity), 0);
    const amount = lineItem.subLineItems.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    const wuwd = lineItem.subLineItems.reduce((sum, item) => sum + parseFloat(item.wuwd), 0);
    const adjustedRate = amount / quantity;

    handleUpdateLineItem(
      index,
      { quantity, amount, wuwd, rate: adjustedRate, subLineItems: lineItem.subLineItems },
      true
    );
  };

  const handleUpdateCreditsUsed = (credits, validate) => {
    if (validate) {
      const lineItemTotal =
        calculateSubTotal(invoice.lineItems.fromTime).sumTotal + calculateSubTotal(invoice.lineItems.standard).sumTotal;

      let creditTotal = 0;
      let maxedOut = false;
      const adjustedCredits = credits.map(credit => {
        creditTotal += +credit.amountToApply;
        if (maxedOut) {
          credit.amountToApply = 0;
          credit.selected = false;
        } else if (creditTotal > lineItemTotal) {
          const overage = creditTotal - lineItemTotal;
          credit.amountToApply -= overage;
          maxedOut = true;
        }
        return credit;
      });

      setAvailableCredits(adjustedCredits);
    } else {
      setAvailableCredits(credits);
    }
    setNeedToSave(true);
  };

  const inviteClient = start => {
    return new Promise(resolve => {
      if (clientUsers.length === 0 && (invoiceType === invoiceTypes.oneTime || (!recurrence.started && start))) {
        if (ft_crm) {
          showInviteClientModal({
            clientId: invoice.client.id,
            onInviteSuccess: newUserIds => {
              if (newUserIds.length) {
                resolve(newUserIds);
              }
            },
            infoText:
              "Your client hasn't joined you on Canopy yet. If you want to send this invoice, you'll need to invite them to Canopy's client portal.",
            disableNotification: false,
            isSurveyNotification: false,
            onClose: () => {
              setDisableActions(false);
            },
          });
        } else {
          showInviteClientModal(
            invoice.client.id,
            newUserIds => {
              if (newUserIds.length) {
                resolve(newUserIds);
              }
            },
            "Your client hasn't joined you on Canopy yet. If you want to send this invoice, you'll need to invite them to Canopy's client portal.",
            false,
            false,
            false,
            'Cancel',
            () => {
              setDisableActions(false);
            }
          );
        }
      } else {
        resolve(clientUsers.map(user => user.id));
      }
    });
  };

  const handlePreview = () => {
    setDisableActions(true);
    const invoiceDto = { ...mapInvoiceFormDataToDto(invoice, options, availableCredits), is_grouped: groupLineItems };
    prepareAndPreviewInvoice(invoiceDto, sourceId)
      .then(invoicePreview => {
        setInvoicePreview(invoicePreview);
        setMode(modes.preview);
        setDisableActions(false);
      })
      .catch(handleError);
  };

  const handleRemoveTimeEntriesFromInvoice = (timeEntriesToRemove, onComplete) => {
    let originalInvoice = mapInvoiceFormDataToDto(invoice, options, availableCredits);

    const updatedInvoice = {
      ...invoice,
      lineItems: {
        ...invoice.lineItems,
        hidden: invoice.lineItems.hidden.filter(item => !timeEntriesToRemove.includes(item.id)),
      },
    };
    const newInvoice = mapInvoiceFormDataToDto(updatedInvoice, options, availableCredits);

    prepareAndSaveInvoice(newInvoice, { draft: invoice.draft, is_sent: !!invoice.sent_at }).then(() => {
      toasts.successToast(`Linked time and expenses have been successfully updated`, 'Undo', () => {
        prepareAndSaveInvoice(originalInvoice, {
          draft: originalInvoice.draft,
          is_sent: !!originalInvoice.sent_at,
        }).then(result => {
          if (result) {
            toasts.successToast(`Linked time and expenses have been successfully restored`);
            onComplete();
          }
        });
      });
      onComplete();
    });
  };

  const storePaymentMethod = async () => {
    const { achInfo, ccInfo, method } = paymentDetails.paymentMethod;
    const clientId = invoice.client?.id;
    let promise = null;

    if (method === 'cpCreditCard' || method.includes('cpStoredCreditCard')) {
      const vaultConfig = {
        vault: {
          holderName: ccInfo.name,
          billingAddress: {
            country: 'US',
            zip: ccInfo.zip,
            state: ccInfo.state,
            city: ccInfo.city,
            street: ccInfo.street,
            street2: ccInfo.street2,
          },
        },
      };
      promise = new Promise((resolve, reject) => {
        paymentDetails.paysafeInstance.tokenize(vaultConfig, function (instance, error, result) {
          if (error) {
            reject(error);
          } else {
            if (ccInfo.isExpired) {
              updateCard(ccInfo.id, {
                card: {
                  card_details: {
                    ...separateName(ccInfo.name),
                  },
                  cc_token: result.token,
                  is_preferred: ccInfo.isPreferred,
                  nickname: ccInfo.nickname,
                },
              }).subscribe(
                () =>
                  resolve({
                    id: ccInfo.id,
                  }),
                handleError
              );
            } else {
              maybeSaveCard(ccInfo, clientId, result.token)
                .then(card => resolve(card))
                .catch(paymentSubmitError => {
                  setErrors(prevErrors => ({ ...prevErrors, paymentSubmitError }));
                });
            }
          }
        });
      });
    } else if (method === 'cpAch') {
      promise = new Promise(resolve => {
        maybeSaveAccount(achInfo, clientId)
          .then(account => resolve(account))
          .catch(paymentSubmitError => {
            setErrors(prevErrors => ({ ...prevErrors, paymentSubmitError }));
          });
      });
    }
    return await promise;
  };

  const handleSave = (draft, start = true, print, download) => {
    setDisableActions(true);
    if (invoiceType === invoiceTypes.oneTime) {
      const invoiceDto = {
        ...mapInvoiceFormDataToDto(invoice, options, availableCredits, true),
        is_grouped: groupLineItems,
      };
      prepareAndSaveInvoice(
        invoiceDto,
        {
          draft,
          is_downloaded: download,
          is_printed: print,
          is_sent: !!invoice.sentAt,
        },
        sourceId
      )
        .then(savedInvoice => {
          if (!print && !download) {
            onClose?.();
          } else {
            handleUpdateInvoiceFormData('id', savedInvoice.invoices.id, false);
            setMode(modes.view);
          }
          setNeedToSave(false);
          toasts.successToast(
            `Invoice #${savedInvoice.invoices.invoice_number} has been saved${draft ? ' as a draft' : ''}`
          );
          window.dispatchEvent(
            new CustomEvent('billing-ui::event-saved', {
              detail: {
                clientId: invoice?.client?.id,
                serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
              },
            })
          );
          setDisableActions(false);
        })
        .catch(handleError);
    } else {
      inviteClient(start).then(async () => {
        let paymentDate = '';
        let cardId = null;
        if (hasCanopyPayments && options.recurringPayments) {
          paymentDate = paymentDetails.paymentDate;
          const { achInfo, ccInfo, method } = paymentDetails.paymentMethod;
          if (
            method.includes('cpStoredBankAccount') ||
            (method.includes('cpStoredCreditCard') &&
              ((!hasAdyen && !ccInfo.isExpired) || (hasAdyen && !adyenPaymentDetails.paymentMethod.isExpired)))
          ) {
            cardId = hasAdyen ? adyenPaymentDetails?.paymentMethod?.id : method.split(' ')[1];

            if (!hasAdyen && method.includes('cpStoredCreditCard') && ccInfo.saveCard) {
              updateCard(cardId, {
                card: {
                  is_preferred: ccInfo.isPreferred,
                },
              }).subscribe(() => {
                window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
              }, handleError);
            } else if (!hasAdyen && method.includes('cpStoredBankAccount') && achInfo.saveAccount) {
              updateBankAccount(cardId, {
                ach: {
                  is_preferred: achInfo.isPreferred,
                },
              }).subscribe(() => {
                window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
              }, handleError);
            } else if (
              (method.includes('cpStoredCreditCard') || method.includes('cpStoredBankAccount')) &&
              hasAdyen &&
              adyenPaymentDetails?.savedMethod
            ) {
              //COMMENT: The above situations are related to Paysafe and should be removed later, Adyen should hit this point
              updateCanopyPayment(cardId, { is_preferred: adyenPaymentDetails?.paymentInfo?.isPreferred }).subscribe(
                () => {
                  window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
                },
                handleError
              );
            }
          } else {
            if (hasAdyen && teamCanKeyInCards && adyenPaymentDetails.adyenSessionDetails) {
              const card = await adyenActions.saveAdyenPaymentMethod();
              cardId = card.card_id;
            } else {
              const card = await storePaymentMethod();
              cardId = card.id;
            }
          }
        }
        const recurrenceDto = prepareRecurrence(
          mapInvoiceFormDataToDto(invoice, options, availableCredits),
          mapRecurrenceFormDataToDto(recurrence),
          start,
          paymentDate,
          cardId
        );
        if (recurrence.id) {
          updateRecurrence({ id: recurrence.id }, recurrenceDto).subscribe(
            () => {
              onClose?.();
              setNeedToSave(false);
              toasts.successToast('Recurring series has been modified');
              window.dispatchEvent(
                new CustomEvent('billing-ui::event-saved', {
                  detail: {
                    clientId: invoice?.client?.id,
                    serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
                  },
                })
              );
              setDisableActions(false);
            },
            err => {
              handleError(err);
              setDisableActions(false);
            }
          );
        } else {
          createRecurrence(recurrenceDto).subscribe(
            () => {
              onClose?.();
              setNeedToSave(false);
              toasts.successToast(`Recurring series has been ${start ? 'created successfully' : 'saved as a draft'}`);
              window.dispatchEvent(
                new CustomEvent('billing-ui::event-saved', {
                  detail: {
                    clientId: invoice?.client?.id,
                    serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
                  },
                })
              );
              setDisableActions(false);
            },
            err => {
              handleError(err);
              setDisableActions(false);
            }
          );
        }
      });
    }
  };

  const handleConfigure = (visibleColumns, grouped) => {
    const oldGroupSetting = groupLineItems;

    setGroupLineItems(grouped);
    handleUpdateInvoiceFormData('columnVisibility', visibleColumns);

    if (oldGroupSetting !== grouped && associatedTimeEntries.length > 0) {
      handleAddTimeEntry(associatedTimeEntries, true, grouped);
    }
  };

  const handleArchive = () => {
    archiveInvoice(invoice.id).subscribe(() => {
      onClose?.();
      toasts.successToast('The invoice has been archived');
      window.dispatchEvent(
        new CustomEvent('billing-ui::event-saved', {
          detail: {
            clientId: invoice?.client?.id,
            serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
          },
        })
      );
    });
  };

  const handleRestore = () => {
    unarchiveInvoice(invoice.id).subscribe(() => {
      handleUpdateInvoiceFormData('archived', false, false);
      toasts.successToast('The invoice is now active');
      window.dispatchEvent(
        new CustomEvent('billing-ui::event-saved', {
          detail: {
            clientId: invoice?.client?.id,
            serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
          },
        })
      );
    });
  };

  const handleDelete = () => {
    if (
      (invoiceType === invoiceTypes.oneTime && !invoice.id) ||
      (invoiceType === invoiceTypes.recurring && !recurrence.id)
    ) {
      onClose?.();
      return;
    }

    setDisableActions(true);
    if (invoiceType === invoiceTypes.oneTime) {
      const hasPayments =
        ['paid', 'partial', 'partial overdue'].includes(invoice.status) &&
        (invoice.paymentsApplied !== 0 || invoice.creditsApplied === invoice.total) &&
        invoice.payments;

      if (!hasPayments) {
        deleteInvoice(invoice).subscribe(() => {
          setDisableActions(false);
          onClose?.();
          toasts.successToast('The invoice has been deleted', 'Undo', () => {
            restoreInvoice(invoice).subscribe(result => {
              if (result) {
                toasts.successToast('Invoice successfully restored');
                window.dispatchEvent(
                  new CustomEvent('billing-ui::event-saved', {
                    detail: {
                      clientId: invoice?.client?.id,
                      serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
                    },
                  })
                );
              }
            });
          });
          window.dispatchEvent(
            new CustomEvent('billing-ui::event-saved', {
              detail: {
                clientId: invoice?.client?.id,
                serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
              },
            })
          );
        });
      }
    } else {
      deleteRecurrence(recurrence.id).subscribe(() => {
        setDisableActions(false);
        onClose?.();
        window.dispatchEvent(
          new CustomEvent('billing-ui::event-saved', {
            detail: {
              clientId: invoice?.client?.id,
              serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
            },
          })
        );
        toasts.successToast('The recurrence has been deleted');
      }, handleError);
    }
  };

  const removeTime = lineItems => {
    const withoutTime = { standard: lineItems.standard, fromTime: [], hidden: [], lateFees: [] };

    lineItems.fromTime.forEach(lineFromTime => {
      if (lineFromTime.rateType === 'employee') {
        lineFromTime.rateType = 'other';
      }
      if (lineFromTime.subLineItems) {
        delete lineFromTime.subLineItems;
      }
      if (lineFromTime.relationships?.sources) {
        delete lineFromTime.relationships.sources;
      }
      lineFromTime.assignee = '';
      lineFromTime.timeEntryDate = null;
      lineFromTime.task = '';
      lineFromTime.subtask = '';
      lineFromTime.expenseId = null;
      withoutTime.standard.push(lineFromTime);
    });

    return withoutTime;
  };

  const handleDuplicate = () => {
    featureEnabled('toggle_gs_invoice_refactor')
      ? (() => {
          history.push({
            pathname: `/billing/invoice/${invoiceType === invoiceTypes.oneTime ? 'single' : 'recurring'}/editor`,
            search: `?duplicateInvoiceId=${invoice.id || recurrence.id}`,
          });
          onClose?.();
        })()
      : setMode(modes.create);

    let duplicateInvoice = getDefaultInvoice();
    let duplicateRecurrence = getDefaultRecurrence();
    let showDateReset = false;

    const today = moment().startOf('day');

    duplicateInvoice = {
      ...(invoiceType === invoiceTypes.oneTime && { isDuplicate: true }),
      columnVisibility: invoice.columnVisibility,
      invoiceDate: duplicateInvoice.invoiceDate,
      lineItems: removeTime(invoice.lineItems),
      terms:
        invoice.terms.id === -1 || invoice.terms.id === undefined ? { id: 0, name: 'Due on Receipt' } : invoice.terms,
      dueDate: invoice.terms.id === -1 ? today : today.add(invoice.terms.id, 'days'),
      singleLineDescription: invoice.singleLineDescription,
      singleLineDiscount: invoice.singleLineDiscount,
      singleLineDiscountIsPercent: invoice.singleLineDiscountIsPercent,
      termsConditions: invoice.termsConditions,
      includeSpouseName: invoice.includeSpouseName,
      isBusinessClient: invoice.isBusinessClient,
    };

    if (invoiceType === invoiceTypes.oneTime) {
      if (moment(invoice.invoiceDate).isBefore(today, 'day')) {
        duplicateInvoice.showDateReset = true;
      }
    } else {
      let newStartDate = moment(recurrence.startDate);
      if (newStartDate.isBefore(today, 'day')) {
        newStartDate = today;
        showDateReset = true;
      }

      duplicateRecurrence = {
        isDuplicate: true,
        ...recurrence,
        started: false,
        status: 'Not Scheduled',
        startDate: newStartDate,
        relationships: {},
        showDateReset,
        remaining: recurrence.numInvoices,
        endAfter: recurrence.numInvoices,
        includeSpouseName: invoice.includeSpouseName,
        isBusinessClient: invoice.isBusinessClient,
      };
      if (hasAdyen) {
        adyenActions.setAdyenSessionDetails();
      }
      duplicateRecurrence = omit(duplicateRecurrence, ['id', 'nextOccurrence', 'payment']);
    }
    setOptions({ ...options, recurringPayments: false, clientNote: false });
    setAssociatedTimeEntries([]);
    setInvoice(duplicateInvoice);
    setRecurrence(duplicateRecurrence);
  };

  const handleSend = () => {
    setDisableActions(true);
    inviteClient().then(clientIds => {
      const invoiceDto = {
        ...mapInvoiceFormDataToDto(invoice, options, availableCredits, true),
        is_grouped: groupLineItems,
      };
      const apiCall = needToSave ? sendInvoice : sendNotification;
      apiCall(invoiceDto, clientIds, sourceId).then(res => {
        toasts.successToast(`Invoice #${res.invoices.invoice_number} has been delivered`);
        window.dispatchEvent(
          new CustomEvent('billing-ui::event-saved', {
            detail: {
              clientId: invoice?.client?.id,
              serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
            },
          })
        );
        setDisableActions(false);
        onClose?.();
      }, handleError);
    });
  };

  const handleStart = () => {
    handleSave(false, true);
  };

  const handleStop = () => {
    handleSave(false, false);
  };

  const handleDownload = save => {
    if (save) {
      handleSave(false, false, false, true);
    } else {
      updateInvoice({ id: invoice.id }, { invoices: { id: invoice.id, is_downloaded: true } }).subscribe();
    }
    webViewerInstance.UI.downloadPdf();
  };

  const handlePrint = save => {
    if (save) {
      handleSave(false, false, true);
    } else {
      updateInvoice({ id: invoice.id }, { invoices: { id: invoice.id, is_printed: true } }).subscribe();
    }

    window.open(`/api/invoices/${invoice.id}/?isPdf=true`, '_blank');
  };

  const handlePay = () => {
    onClose?.(true, { invoice: mapInvoiceFormDataToDto(invoice, options, availableCredits) });
  };

  const actions = {
    typeChange: setInvoiceType,
    modeChange: setMode,
    toggleOption: handleToggleOption,
    loadInvoice: handleLoadInvoice,
    loadRecurrence: handleLoadRecurrence,
    updateInvoiceFormData: handleUpdateInvoiceFormData,
    updateRecurrenceFormData: handleUpdateRecurrenceFormData,
    updateRecurringPayment: handleUpdateRecurringPayment,
    paysafeChanged: handlePaysafeChanged,
    addLineItem: handleAddLineItem,
    addTimeEntry: handleAddTimeEntry,
    updateLineItem: handleUpdateLineItem,
    filterEntryItems: handleFilterEntryItems,
    removeLineItem: handleRemoveLineItem,
    removeTimeEntry: handleRemoveTimeEntry,
    removeTimeEntriesFromInvoice: handleRemoveTimeEntriesFromInvoice,
    updateCreditsUsed: handleUpdateCreditsUsed,
    initializeWebViewer: setWebViewerInstance,
    preview: handlePreview,
    save: handleSave,
    archive: handleArchive,
    restore: handleRestore,
    delete: handleDelete,
    duplicate: handleDuplicate,
    send: handleSend,
    start: handleStart,
    stop: handleStop,
    configure: handleConfigure,
    download: handleDownload,
    print: handlePrint,
    addPayment: handlePay,
    viewInvoiceFromRecurrence: onViewInvoiceFromRecurrence,
    returnToRecurrence: onReturnToRecurrence,
    adyenActions,
  };

  return {
    invoice,
    recurrence,
    availableCredits,
    groupLineItems,
    invoicePreview,
    webViewerInstance,
    clientGroupClients,
    invoiceType,
    mode,
    options,
    actions,
    needToSave,
    errors,
    disableActions,
    adyenPaymentDetails,
  };
};
