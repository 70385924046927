import { useState, useMemo, useEffect, useRef } from 'react';
import { without } from 'lodash';
import { forkJoin, of, combineLatest } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';

import { handleError } from 'src/common/handle-error.helper';
import { getCategories } from 'src/resources/integrations.resources';
import { getServiceItems } from 'src/resources/service-items.resources';
import { formatCategorizedQboItems, getQboFromIntegrationsObj } from 'src/common/integrations/qbo.helpers';

import { integrationsQueries } from 'src/queries/integrationsQueries';
import { useQuery } from 'src/react-query';

export const useInputWithDisplayValue = (initialValue, formatter) => {
  const [showInput, setShowInput] = useState(false);
  const [boundValue, setBoundValue] = useState(initialValue);
  const [displayValue, setDisplayValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  useEffect(() => {
    if (!showInput) {
      setDisplayValue(formatter(boundValue));
    }
  }, [showInput, boundValue, formatter]);

  return {
    boundValue,
    setBoundValue,
    displayValue,
    showInput,
    setShowInput,
    inputRef,
  };
};

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        return item;
      }
    }
    return initialValue;
  });

  const setValue = value => {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
};

export const useSessionStorage = key => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        return item;
      }
    }
  });

  const setValue = value => {
    setStoredValue(value);
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
};

export const useFieldValidation = initialInvalids => {
  const [invalidFields, setInvalidFields] = useState(initialInvalids);

  const validateNotEmpty = (value, field) => {
    if (!value) {
      setInvalidFields([...invalidFields, field]);
    }
  };

  const clearInvalidFields = (...field) => {
    setInvalidFields(without(invalidFields, ...field));
  };

  return [invalidFields, setInvalidFields, validateNotEmpty, clearInvalidFields];
};

export const useServiceItemsIntegrations = noFetch => {
  const [qboConnection, setQboConnection] = useState();
  const [allServiceItems, setAllServiceItems] = useState([]);
  const [qboCategories, setQboCategories] = useState([]);
  const [, tenant] = useWithUserAndTenant();

  const { data: integrationsData } = useQuery(
    integrationsQueries.accountingIntegrations({ enabled: !!tenant?.qbo_credentials_id && !noFetch })
  );

  useEffect(() => {
    if (noFetch) return;

    const subscription = forkJoin([getServiceItems(), of(integrationsData || [])])
      .pipe(
        mergeMap(([serviceItems, integrations]) => {
          const qboConnection = getQboFromIntegrationsObj(integrations);

          return combineLatest(of(serviceItems), of(qboConnection));
        }),
        map(([serviceItems, qboConnection]) => [serviceItems, qboConnection])
      )
      .subscribe(([serviceItems, qboIntegrationInfo]) => {
        if (qboIntegrationInfo) {
          setQboConnection(qboIntegrationInfo);
        }

        setAllServiceItems(serviceItems);
      }, handleError);

    return () => {
      subscription.unsubscribe();
    };
  }, [tenant?.qbo_credentials_id, noFetch]);

  useEffect(() => {
    if (noFetch) return;

    if (qboConnection?.connected && qboConnection?.id) {
      const subscription = getCategories(qboConnection.id, qboConnection.type).subscribe(categories => {
        setQboCategories(categories);
      }, handleError);

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [qboConnection?.id, qboConnection?.connected, noFetch]);

  const categorizedServices = useMemo(() => {
    if (allServiceItems?.length) {
      const formats = allServiceItems?.map(val => {
        const syncedToThirdParty = !!val.third_party_id;
        return { ...val, ...(!!syncedToThirdParty ? { ...val?.third_party_data, syncedToThirdParty } : {}) };
      });

      if (!qboCategories?.length) return formats;

      return formatCategorizedQboItems(formats, qboCategories);
    } else {
      return null;
    }
  }, [allServiceItems?.length, qboCategories?.length]);

  return { categorizedServices, allServiceItems, qboCategories };
};
